<template>
  <div class="documents">
    <p class="documents__title">{{ $t('documents') }}</p>
    <div class="documents__items">
      <a v-for="(item, key) in getUser.documentsSrc" :key="key" :href="item.fileSrc" target="_blank" class="documents__item" >
        <img src="@/assets/svg/icon-docs-red.svg" alt="">
        <div class="row-text">
          {{ item.name }}
        </div>
      </a>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { formatPhone, getDate } from "@/utils/general";

export default {
  name: "Documents",
  data() {
    return {
      formatPhone,
      getDate
    }
  },

  computed: {
    ...mapGetters([
      'getUser'
    ]),
  },
}
</script>

<style lang="scss" scoped>
.documents {
  background: #FFFFFF;
  border-radius: 20px;
  padding: 40px;

  @media (max-width: 1200px) {
    padding: 30px 12px;
  }

  &__title {
    font-weight: 400;
    font-size: 16px;
    line-height: 15px;
    color: #343432;
    margin-bottom: 30px;
  }

  &__items {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;

    img {
      width: 50px;
      height: 50px;

      @media (max-width: 406px) {
        display: none;
      }
    }
    .row-text {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @media (max-width: 406px) {
        color: #CE2121;
      }

      span {
        font-weight: 400;
        font-size: 12px;
        line-height: 11px;
        color: #9A9A9A;
      }
    }
  }
}
</style>
